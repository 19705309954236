<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-card class="pa-2 ma-2" outlined tile>
          <h1 style="text-align: center">{{ options.title }}</h1>
          <v-tabs
            class="elevation-2"
            dark
            :grow="true"
            show-arrows
            v-model="activeTab"
          >
            <v-tabs-slider :color="colorSlider"></v-tabs-slider>
            <v-tab
              v-for="tab in options.tabs"
              :key="tab.name"
              :href="`#tab-${tab.id}`"
              @click="selectTab(tab)"
              :style="{ color: colorText }"
            >
              {{ tab.name }}
            </v-tab>
            <v-tabs-items v-model="activeTab">
              <v-tab-item
                v-for="(item, index) in componentList"
                :key="index"
                :value="`tab-${index}`"
              >
                <component
                  :is="item.name"
                  v-bind="{
                    tag: item.props.tag
                  }"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "PlannedIndicators",
  components: {
    KPICard: () => import("@/views/Analytics/Kpi/CustomerKPI.vue")
  },
  computed: {
    options: get("report/optionsKPI")
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      activeTab: 0,
      colorText: "#BBDEFB",
      colorSlider: "#BBDEFB",
      isLoaded: false,
      items: [],
      componentList: [
        {
          name: "KPICard",
          props: {
            tag: {
              value: "produce",
              text: "Добыча"
            }
          }
        },
        {
          name: "KPICard",
          props: {
            tag: {
              value: "refinery",
              text: "Переработка"
            }
          }
        },
        {
          name: "KPICard",
          props: {
            tag: {
              value: "export",
              text: "Экспорт"
            }
          }
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      fetchKPIPlans: "report/fetchKPIPlans"
    }),
    async initialize() {
      await this.fetchKPIOrganizations();
    },
    selectTab(tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab;
      }
    }
  },
  async created() {
    await this.initialize();
  }
});
</script>
